<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vipSet' }">会员详情</el-breadcrumb-item>
                <el-breadcrumb-item>会员卡详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="course-detail-title" style="">
            <div style="padding:10px">
                <div class="fl-l" style="width: 150px;"><img width="150px" :src="imageUrl+form.link" /></div>
                <div class="fl-l margin-l-sm">
                    <p class="title">名称：{{form.name}}</p>
                    <p class="price">价格：￥<span style="color:red">{{form.price}}</span></p>
                    <p class="price">有效期：{{form.days}}天</p>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="margin-sm">

            <el-tabs v-model="activeName" type="card" @tab-click="change">
                <el-tab-pane label="会员卡选项" name="manage" path="manage">
                    <div class="vip-card-periods" style="padding:10px">
                        <span v-html="form.detail"></span>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="学员管理" name="student" path="student" v-loading="tabLoading">

                    <div>

                        <div class="margin-t-sm">
                            <span class="fl-l">
                                <el-select v-model="searchType" clearable slot="prepend" placeholder="选择类型" size="mini">
                                    <el-option label="微信昵称" :value="1"></el-option>
                                    <el-option label="UID" :value="2"></el-option>
                                </el-select>
                                <el-input class="margin-l-sm" v-model="userName" size="mini" style="width:160px"
                                    clearable>
                                </el-input>
                            </span>
                            <span class="margin-l-sm fl-l">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索
                                </el-button>
                            </span>
                            <div class="table-content">

                                <el-table :data="tableData" style="width: 100%">
                                    <el-table-column prop="id" label="id">
                                    </el-table-column>
                                    <el-table-column prop="user_id" label="uid">
                                    </el-table-column>
                                    <el-table-column prop="name" label="姓名">
                                    </el-table-column>

                                    <el-table-column label="头像" width="180">
                                        <template slot-scope="scope">
                                            <el-avatar shape="square" :size="50" :src="scope.row.avatar_url"
                                                icon="el-icon-user-solid">
                                            </el-avatar>
                                        </template>
                                    </el-table-column>


                                    <el-table-column prop="expire_at" label="过期时间">
                                    </el-table-column>

                                    <el-table-column prop="created_at" label="创建时间">
                                    </el-table-column>


                                </el-table>

                            </div>

                            <div class="table-batch ">
                                <span class="fl-r padding-b-sm">
                                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]"
                                        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                                        @size-change="setPageSize" @current-change="setPage" :total="total">
                                    </el-pagination>
                                </span>
                                <div style="clear: both"></div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "detail",
    data() {
        return {
            tabLoading: false,
            loading: false,
            imageUrl: config.imageUrl,
            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,
            id: null,
            form: {},
            activeName: 'manage',
            searchType: null,
            searchData: {},
            userName: ''
        };
    },
    methods: {
        ...mapActions('vipSet', ['audioBooksVipGet']),
        ...mapActions('user', ['getUserVipList']),
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getUserVip()
        },
        search() {
            if (this.searchType == 1)
                this.searchData.nickName = this.userName
            else
                this.searchData.userId = this.userName ?? 0
            this.getUserVip()
        },
        setPage(page) {
            this.page = page
            this.getUserVip()
        },
        async change(e) {

            this.page = 1
            await this.getUserVip()

        },
        async getUserVip() {
            this.tabLoading = true
            const { data } = await this.getUserVipList({ module: this.id, ...this.searchData })
            console.log(data);
            this.tableData = data.list
            this.total = data.total
            this.tabLoading = false
        },
        async getDetail() {
            this.loading = true
            const { data } = await this.audioBooksVipGet({ module: this.id, })
            console.log(data)
            this.form = data
            this.loading = false
        },
    },
    mounted() {
        this.id = this.$route.params.id
        this.getDetail()
    }
}
</script>

<style>
.course-detail-title {
    margin: 10px;
    background: #fafafa;
    overflow: hidden;
}

.title {
    font-size: 18px;
    margin-top: 5px;
}

.price {
    margin-top: 20px;
    font-size: 16px;
}

.real-amount {
    margin-top: 10px;
    text-decoration: line-through
}
</style>